import React from "react";
import { graphql, Link } from 'gatsby';
import Img from "gatsby-image";
import Layout from "../layouts/layout";
import LargeCard from "../components/large-card";

const SolutionsServices = ({ data: { hero, previewSections } }) => {

    return (
        <Layout hero={hero}>

            <div className="container mx-auto mb-16 px-4">
                <LargeCard data={previewSections.edges[0].node} />
            </div>

            <div className="container mx-auto mb-16 px-4">

                <div className="mt-4 sm:flex flex-wrap justify-between">
                    {previewSections.edges.slice(1).map(({ node }) =>
                        <div className="border-shadow cards-three flex flex-col mt-4" key={node.id}>
                            {node.link ?
                                <Link to={`/solutions-services/${node.link.slug}`}>
                                    <Img className="sm:h-48 lg:h-64 object-cover cursor-pointer" fluid={node.previewImage.fluid} />
                                </Link>
                            :
                                <a href={node.externalLink} target="_blank" >
                                    <Img className="sm:h-48 lg:h-64 object-cover cursor-pointer" fluid={node.previewImage.fluid} />
                                </a> 
                            }
                            <div className="mt-2 p-4">
                                <h3 className="text-gray-800 text-xl font-bold mb-2">{node.title}</h3>
                                <p className="text-sm soft-gray">{node.previewText}</p>
                            </div>
                            <div className="p-4 mb-6 mt-auto">
                                <div className="inline-block uppercase border-b-2 text-xs border-mane-yellow text-gray-800 font-bold">
                                    <a href={node.externalLink} target="_blank" >Learn More</a>
                                </div>
                            </div>

                        </div>

                    )}
                </div>
            </div>


        </Layout>
    )
};

export const query = graphql`
    query solutionsServices {
       hero: contentfulSolutionsServicesPage {
            heroHeading {
                childMarkdownRemark {
                    html
                }
            }
            heroBackgroundImage {
                resize (width: 1440) {
                    src 
                }
            }
       }
       previewSections: allContentfulServicesPreviewSections(sort: {fields: sortOrder}) {
           edges {
             node {
                 title
                 previewText
                 previewImage {
                   fluid {
                       ...GatsbyContentfulFluid_withWebp
                   }
                 }
                 externalLink
                 link {
                    slug
                 }
            }
          }
     }


   }
`;


export default SolutionsServices;
