import React from "react"
import Img from "gatsby-image";
import { Link } from "gatsby";

const LargeCard = ({data}) => (
    <div className="sm:flex justify-between card large-card border-shadow pr-4">

        <div className="sm:flex-1 sm:ml-0">
            <Link to={data.link && `/solutions-services/${data.link.slug}`}>
                <Img alt="promo image" fluid={data.previewImage.fluid} />
            </Link>
        </div>

        <div className="flex-1 sm:ml-10 p-4 py-8 ">
            <h2 className="text-3xl sm:text-5xl font-bold dark-blue leading-tight">{data.title}</h2>
            <p className="mt-8">{data.previewText}</p>
            <div className="inline-block uppercase pt-6 border-b-2 text-xs border-mane-yellow text-gray-800 font-bold">
                <Link to={data.link && `/solutions-services/${data.link.slug}`}>
                    Learn More
                </Link>
            </div>
        </div>

    </div>

)
export default LargeCard
